/* -------------------------------------------------------------------------- */
/*                       🗳 Vote entry with mode switch                       */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import { Event, Vote } from '../../../services/modelTypes'

/* -------------------------------- 📷 Images ------------------------------- */
import Checkmark from './assets/Checkmark.svg'
import CheckmarkFilled from './assets/CheckmarkFilled.svg'
import Cross from './assets/Cross.svg'
import CrossFilled from './assets/CrossFilled.svg'
import Docs from './assets/Docs.svg'
import StarFilled from './assets/StarFilled.svg'
import StarOutline from './assets/StarOutline.svg'
import StarWhiteFilled from './assets/StarWhiteFilled.svg'
import StarWhiteOutline from './assets/StarWhiteOutline.svg'
import { Button } from '../../common/Button'

interface IYesNoEntryProps {
  selectedValue: 0 | 5
}

export type VOTE_CATEGORIES = 'pitch' | 'business' | 'product'
export type VALID_STAR_VOTES = number
export type CombinedVote = {
  [k in VOTE_CATEGORIES]: VALID_STAR_VOTES
} | VALID_STAR_VOTES

interface IStarEntryProps {
  selectedValue: CombinedVote
}


export interface IVoteEntryProps {
  voteType: Event['votingType'] | 'none',
  voteData: IYesNoEntryProps | IStarEntryProps,
  number: number,
  title: string,
  subtitle: string,
  onVotesChange: (stars: CombinedVote) => void
  onShowDetails?: () => void
}

export class VoteEntry extends React.Component<IVoteEntryProps> {
  static defaultProps: Partial<IVoteEntryProps> = {
    voteType: 'yesno'
  }

  onVote = (score: CombinedVote) => this.props.onVotesChange(score)

  get votingIcons() {
    const { voteType, voteData } = this.props

    switch (voteType) {
      case 'yesno':
        return <YesNoVoteIcons
          {...voteData as IYesNoEntryProps}
          onVote={this.onVote}
        />
      case 'star':
        return <StarIcons
          {...voteData as IStarEntryProps}
          onVote={this.onVote}
        />
      case 'none':
      default:
        return null
    }
  }

  get detailsIcon() {
    if (!('onShowDetails' in this.props)) {
      return null
    }

    return <img
      onClick={this.props.onShowDetails}
      className='icon'
      src={Docs}
      alt=''
    />
  }

  public render() {
    const { number, title, subtitle, voteType } = this.props
    return <div className={`VoteEntry ${voteType}`}>
      <h1 className="number">{number}</h1>
      <div className="text">
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      {this.detailsIcon}
      {this.votingIcons}
    </div>
  }
}

/* ------------------------ 🕹 YesNo icons component ------------------------ */

interface IYesNoVoteIconsProps extends IYesNoEntryProps {
  onVote: (score: Vote['value']) => void
}

class YesNoVoteIcons extends React.Component<IYesNoVoteIconsProps> {
  get icons() {
    switch (this.props.selectedValue) {
      case 5:
        return {
          checkmark: CheckmarkFilled,
          cross: Cross
        }
      case 0:
        return {
          checkmark: Checkmark,
          cross: CrossFilled
        }
      default:
        return {
          checkmark: Checkmark,
          cross: Cross
        }
    }
  }

  public render() {
    return (
      <div className='YesNoVoteIcons'>
        <img
          src={this.icons.checkmark}
          alt=""
          className="icon"
          onClick={() => this.props.onVote(5)}
        />
        <img
          src={this.icons.cross}
          alt=""
          className="icon"
          onClick={() => this.props.onVote(0)}
        />
      </div>
    )
  }
}

/* -------------------------- ⭐ Star vote component ------------------------- */

interface IStarIconsProps extends IStarEntryProps {
  onVote: (score: CombinedVote, category?: VOTE_CATEGORIES) => void
}

interface IStarIconsState {
  isOpen: boolean
}

class StarIcons extends React.Component<IStarIconsProps, IStarIconsState> {
  state: IStarIconsState = {
    isOpen: false
  }

  openVote = () => this.setState({ isOpen: true })
  vote = (value: number, category?: VOTE_CATEGORIES) => {
    if (category && typeof this.props.selectedValue !== 'number') {
      this.props.onVote({
        business: (category === 'business' ? value : this.props.selectedValue.business),
        product: (category === 'product' ? value : this.props.selectedValue.product),
        pitch: (category === 'pitch' ? value : this.props.selectedValue.pitch),
      })
    } else {
      this.props.onVote(value)
    }
  }

  getStarsFor = (category?: VOTE_CATEGORIES) => {
    let { selectedValue } = this.props

    if (selectedValue === null || typeof selectedValue !== 'number') {
      selectedValue = 0
    }

    const values = Array(5).fill(1).map((_, i) => i + 1)
    const chkValue = category && typeof this.props.selectedValue !== 'number' ? this.props.selectedValue[category] : selectedValue

    return <div className="starRow">
      {category ? category : ''}
      <div className="stars">
        {
          values.map(
            (i) => <div className="iconContainer" onClick={() => this.vote(i as Vote['value'], category)}>
              <img
                alt=""
                className="icon"
                src={chkValue >= i ? StarWhiteFilled : StarWhiteOutline}
              />
            </div>
          )
        }
      </div>
    </div>
  }

  public render() {
    const { selectedValue } = this.props
    const { isOpen } = this.state

    const isCategoryVoting = selectedValue !== null && typeof selectedValue !== 'number'

    let selectedValueKeys: number

    if (typeof selectedValue === 'number') {
      selectedValueKeys = selectedValue
    } else {
      selectedValueKeys = selectedValue.business + selectedValue.product + selectedValue.pitch
    }

    return (
      <div className='StarIcons'>
        <span onClick={this.openVote}>
          {selectedValueKeys}
        </span>
        <img src={
          selectedValue === null || selectedValue >= 1 || selectedValueKeys >= 1
            ? StarFilled
            : StarOutline
        }
          onClick={this.openVote}
          className='icon'
          alt=''
        />
        <div className={`StarSelection ${isOpen && 'open'}`}>
          <div className={`container ${isCategoryVoting && 'catVoting'}`}>
            {
              isCategoryVoting ?
                <div className="categoryVoting">
                  {this.getStarsFor('business')}
                  {this.getStarsFor('product')}
                  {this.getStarsFor('pitch')}
                </div>
                : this.getStarsFor()
            }
            <Button buttonType='primary' onClick={() => this.setState({ isOpen: false })}>Close</Button>
          </div>
        </div>
      </div>
    )
  }
}
