/* -------------------------------------------------------------------------- */
/*                             🗳 Voting services                             */
/* -------------------------------------------------------------------------- */

import { Vote } from './modelTypes'

import Axios from 'axios'

export async function vote(code: number, teamId: number, score: Vote['value']) {
  try {
    await Axios.post(`/api/vote/${teamId}`, {
      code,
      score
    })
  } catch (e) {
    // console.log(e.response);
    alert(e.response.data)
  }
}
