/* -------------------------------------------------------------------------- */
/*                               🔒 Login route                               */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import { LoginModal } from '../components/Login'
import { Header } from '../components/common/Header'
import { LoginData, APIVote, APIEvent } from '../services/apiTypes'
import { auth } from '../services/auth'
import { getEvents, getVotes } from '../services'

export interface ILoginRouteProps {
  onLogin: (data: LoginData & { votes: APIVote[], events: APIEvent[] }, code: number) => void
}

export interface ILoginRouteState {
  isInvalid: boolean
  isError: boolean
  isLoading: boolean
}

export default class LoginRoute extends React.Component<ILoginRouteProps, ILoginRouteState> {
  state: ILoginRouteState = {
    isInvalid: false,
    isError: false,
    isLoading: false
  }

  async componentDidMount() {
    const rawCode = localStorage.getItem('code')

    if (rawCode !== null) {
      const code = parseInt(rawCode, 10)

      this.setState({ isLoading: true })
      try {

        const response = await auth(code)
        const events = await getEvents(code)
        const votes = await getVotes(code)

        if (response !== null) {
          this.props.onLogin({ ...response, events, votes }, code)
        } else {
          localStorage.clear()
        }
        this.setState({ isLoading: false })
      } catch (e) {
        console.error(e)
        this.setState({ isError: true, isLoading: false })
      }
    }
  }

  checkCode = async (code: number) => {
    if ((code as unknown) === '' || code === 0) {
      this.setState({ isInvalid: true })
      return
    }
    this.setState({ isLoading: true })

    try {
      const response = await auth(code)

      const events = await getEvents(code)
      const votes = await getVotes(code)

      if (response === null) {
        this.setState({ isInvalid: true, isLoading: false })
        localStorage.clear()
      } else {
        this.props.onLogin({ ...response, events, votes }, code)
        localStorage.setItem('code', code.toString())
      }
    } catch (e) {
      if (e.message.includes('404')) {
        this.setState({ isError: false, isInvalid: true, isLoading: false })
      } else {
        this.setState({ isError: true, isLoading: false })
        localStorage.clear()
      }
    }
  }

  public render() {
    return (
      <div className='fill'>
        <Header />
        <LoginModal
          onLogin={this.checkCode}
          isError={this.state.isError}
          isLoading={this.state.isLoading}
          isInvalid={this.state.isInvalid}
        />
      </div>
    )
  }
}
