/* -------------------------------------------------------------------------- */
/*                                📦 Container                                */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'

export interface IContainerProps {
  children: any
}

export default class Container extends React.Component<IContainerProps> {
  public render() {
    return (
      <div className='Container'>
        {this.props.children}
      </div>
    )
  }
}
