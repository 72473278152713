/* -------------------------------------------------------------------------- */
/*                             🔖 Header component                            */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'

import Logo from './assets/logo.svg'
import { APIEvent } from '../../../services/apiTypes'

export interface HeaderData {
  name: string,
  events: APIEvent[],
  votesCount: number,
  canCrowdvote : boolean,
  selectedEventId: number,
  onEventChange: (eventId: number) => void
  onLogout: () => void
}

export class HeaderDataComp extends React.Component<HeaderData> { }

export interface IHeaderProps<T extends HeaderData> {
  isLoggedIn: boolean,
  data: T extends 'name' ? HeaderData : undefined
}

export class Header extends React.Component<IHeaderProps<HeaderData | any>> {
  static defaultProps: Partial<IHeaderProps<HeaderData>> = {
    isLoggedIn: false
  }

  onLogout = () => {
    if (window.confirm('🚪 Are you sure you want to logout ?')) {
      this.props.data?.onLogout()
    }
  }

  get events() {
    if (this.props.isLoggedIn && this.props.data) {
      const data = this.props.data

      return <EventsSelector
        votesCount={data.votesCount}
        events={data.events}
        onChange={data.onEventChange}
        selectedEventId={data.selectedEventId}
      />
    } else {
      return null
    }
  }

  get userInfo() {
    if (this.props.isLoggedIn && this.props.data) {
      const data = this.props.data

      return <div className="userInfo" onClick={this.onLogout}>
        <h1>Hello, {data.name}!</h1>
        <h2>Logout <span role='img' aria-label=''>🚪</span></h2>
        
      </div>
    } else {
      return null

    }
  }

  get crowdvoteButton() {
    if (this.props.isLoggedIn && this.props.data?.canCrowdvote === true) {
      // const data = this.props.data

      if ( window.location.hostname.split(".")[0] === "crowdvoting" ){
        return <div className = "crowdvoting-button" >
          <a style = {{textDecoration:"none", color: "black"}} target = "_blank" rel="noopener noreferrer" href="https://voting.innovationlabs.ro/"> Go to voting </a>
        </div>
      }
      else{
        return <div className = "crowdvoting-button" >
        <a style = {{textDecoration:"none", color: "black"}} target = "_blank" rel="noopener noreferrer" href="https://crowdvoting.innovationlabs.ro/"> Go to crowdvoting </a>
      </div>
      }
      

    } else {
      return null

    }
  }

  public render() {
    const { isLoggedIn } = this.props

    return (
      <header>
        <div className={`eventSelector ${isLoggedIn && 'open'}`}>
          {this.events}
        </div>
        <div className = "middle-header">
          <img src={Logo} alt='' />
          {this.crowdvoteButton}
        </div>
        <div className={`userInfo ${isLoggedIn && 'open'}`}>
          {this.userInfo}
        </div>
      </header>
    )
  }
}

interface IEventsSelectorProps {
  events: APIEvent[],
  votesCount: number,
  selectedEventId: number,
  onChange: (eventId: number) => void
}

interface IEventsSelectorState {
  isOpen: boolean
}

class EventsSelector extends React.Component<IEventsSelectorProps, IEventsSelectorState> {
  state: IEventsSelectorState = {
    isOpen: false
  }

  toggleDropdown = () => this.setState({ isOpen: !this.state.isOpen })
  onEventChange = (id: number) => {
    this.toggleDropdown()
    this.props.onChange(id)
  }

  get event(): APIEvent {
    const { selectedEventId } = this.props
    const selectedEvent = this.props.events.find(e => e.id === selectedEventId)

    if (!selectedEvent) {
      return this.props.events[0]
    } else {
      return selectedEvent
    }
  }

  get dropdown() {
    const { events, selectedEventId } = this.props
    const filteredEvents = events.filter(e => e.id !== selectedEventId)

    return <div className="dropdown">
      {filteredEvents.map((e) => this.getEvent(e, () => this.onEventChange(e.id)))}
    </div>
  }

  getEvent = (event: APIEvent, onClick?: () => void) => <div
    className="details"
    onClick={onClick}>
    <h1>{event.name}</h1>
    <h2 className={this.props.votesCount > event.warningAt ? 'warn' : ''}>
      {event.warningAt !== 0 && `${this.props.votesCount} votes. Max ${event.warningAt} recommended.`}
    </h2>
  </div>

  public render() {
    const { isOpen } = this.state

    return (
      <div className={`EventsSelector ${isOpen && 'open'}`}>
        {this.getEvent(this.event, this.toggleDropdown)}
        {this.dropdown}
      </div>
    )
  }
}
