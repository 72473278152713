import Axios from 'axios'
import { APIEvent, APIVote } from './apiTypes'

export async function getEvents(code: number): Promise<APIEvent[]> {
  try {
    const response = await Axios.get(`/api/events/`, {
      headers: {
        'x-auth-code': code
      }
    })

    return response.data
  } catch (e) {
    throw new Error(e)
  }
}

export async function getVotes(code: number): Promise<APIVote[]> {
  try {
    const response = await Axios.get(`/api/votes/`, {
      headers: {
        'x-auth-code': code
      }
    })

    return response.data
  } catch (e) {
    alert(e.response.data)
    throw new Error(e)
  }
}
