/* -------------------------------------------------------------------------- */
/*                             🔒 Login page modal                            */
/* -------------------------------------------------------------------------- */

import './index.scss'
import React from 'react'
import { Button } from '../common/Button'

export interface ILoginModalProps {
  onLogin: (code: number) => void,
  isInvalid?: boolean
  isError?: boolean
  isLoading?: boolean
}

export interface ILoginModalState {
  code: number | '',
}

export class LoginModal extends React.Component<ILoginModalProps, ILoginModalState> {
  state: ILoginModalState = {
    code: ''
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({
    code: parseInt(e.target.value, 10)
  })

  onLogin = () => this.props.onLogin(this.state.code as number)

  get warning() {
    let text = ''

    if (this.props.isLoading) {
      text = 'Checking code...'
    } else if (this.props.isError) {
      text = 'An error occured. Try again later.'
    } else if (this.props.isInvalid) {
      text = 'Invalid code. Try again.'
    }

    if (this.props.isInvalid || this.props.isLoading || this.props.isError) {
      return <div className={`warning ${this.props.isError && 'error'} ${this.props.isLoading && 'loading'}`}>
        {text}
      </div>
    } else {
      return null
    }
  }

  public render() {
    const { code } = this.state

    return (
      <div className='LoginModal'>
        {this.warning}
        <h1>Enter login code</h1>
        <input
          placeholder='Type login code'
          type='number'
          value={code}
          onChange={this.onChange}
        />
        <Button onClick={this.onLogin}>Login</Button>
      </div>
    )
  }
}
