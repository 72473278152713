/* -------------------------------------------------------------------------- */
/*                            🧠 Main router logic                            */
/* -------------------------------------------------------------------------- */

import './index.scss'
import * as React from 'react'
import { LoginData, APIEvent, APIVote } from '../services/apiTypes'
import Container from '../components/Container'
import LoginRoute from './Login'
import VotingRoute from './Voting'
import { auth } from '../services/auth'
import { getEvents, getVotes } from '../services'

export interface IRouterProps {
}
export type IRouterState = {
  isLoggedIn: false,
  data: null,
  code: null
} | {
  isLoggedIn: true,
  data: LoginData & {
    events: APIEvent[],
    votes: APIVote[]
  },
  code: number
}

export default class Router extends React.Component<IRouterProps, IRouterState> {
  state: IRouterState = {
    isLoggedIn: false,
    data: null,
    code: null
  }

  updateData = async () => {
    const { code } = this.state
    if (code === null) {
      return
    }

    const data = await auth(code)

    if (data === null) {
      return
    }

    const events = await getEvents(code)
    const votes = await getVotes(code)
    this.setState({ data: { name: data?.name, events, votes, canCrowdvote: data.canCrowdvote} })
  }

  get content() {
    const { isLoggedIn, data } = this.state

    if (!isLoggedIn || data === null) {
      return <LoginRoute
        onLogin={(data, code) => this.setState({ isLoggedIn: true, data, code })}
      />
    } else {
      return <VotingRoute
        updateData={this.updateData}
        data={data}
        code={this.state.code as number}
        onLogout={() => {
          this.setState({ isLoggedIn: false, data: null })
          localStorage.clear()
        }
        }
      />
    }
  }

  public render() {
    return (
      <Container>
        {this.content}
      </Container>
    )
  }
}
