/* -------------------------------------------------------------------------- */
/*                          🔒 Auth service functions                         */
/* -------------------------------------------------------------------------- */

import Axios from 'axios'
import { LoginData } from './apiTypes'

export async function auth(code: number): Promise<LoginData | null> {
  try {
    const response = await Axios.post(`/api/auth/`, { code })
    console.log(response.data)
    return response.data
  } catch (e) {
    if (e.response.status === 404 || e.message.includes('404')) {
      return null
    }

    throw new Error(e)
  }
}
