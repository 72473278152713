/* -------------------------------------------------------------------------- */
/*                              🗳 Voting screen                              */
/* -------------------------------------------------------------------------- */

import * as React from 'react'
import { Header } from '../components/common/Header'
import {
  VoteEntry,
  VOTE_CATEGORIES,
  VALID_STAR_VOTES,
  CombinedVote,
} from '../components/VotingScreen/VoteEntry'
import { TeamDetails } from '../components/VotingScreen/TeamDetails'
import { LoginData, APITeam, APIEvent, APIVote } from '../services/apiTypes'
import { vote } from '../services/vote'
import { Button } from '../components/common/Button'

type Vote = {
  teamId: number
  value:
    | {
        [k in VOTE_CATEGORIES]: VALID_STAR_VOTES
      }
    | VALID_STAR_VOTES
}

export interface IVotingRouteProps {
  data: LoginData & {
    events: APIEvent[]
    votes: APIVote[]
  }
  code: number
  onLogout: () => void
  updateData: () => Promise<void>
}
export interface IVotingRouteState {
  isDetailPage: boolean
  isLoading: boolean
  detailPageId: number
  selectedEvent: number
  votes: Vote[]
}

export default class VotingRoute extends React.Component<
  IVotingRouteProps,
  IVotingRouteState
> {
  state: IVotingRouteState = {
    isDetailPage: false,
    isLoading: false,
    detailPageId: 0,
    selectedEvent: 0,
    votes: [],
  }

  componentDidMount() {
    const lsVotes = localStorage.getItem('votes')
    if (lsVotes !== null) {
      this.setState({
        selectedEvent: this.props.data.events[0].id,
        votes: JSON.parse(lsVotes as string),
      })
    } else if (this.props.data.events[0].votingType === 'star') {
      this.setState({
        selectedEvent: this.props.data.events[0].id,
        votes: this.props.data.votes.map(v => ({
          teamId: v.teamId,
          value: { business: 0, product: 0, pitch: 0 },
        })),
      })
    } else {
      this.setState({
        selectedEvent: this.props.data.events[0].id,
        votes: this.props.data.votes,
      })
    }

    window.addEventListener('beforeunload', this.pageLeaveCheck)
  }

  pageLeaveCheck = (e: BeforeUnloadEvent) => {
    if (this.state.isLoading) {
      e.preventDefault()
      e.returnValue = ''
    }
  }

  getTeamVotes = (teamId: number) => {
    const vote = this.state.votes.find(v => v.teamId === teamId)

    if (this.event.votingType === 'yesno') {
      if (vote) {
        return vote.value === 5 ? 5 : 0
      } else {
        return null
      }
    } else if (this.event.votingType === 'star') {
      if (vote) {
        return vote.value
      } else {
        return null
      }
    } else {
      return null
    }
  }

  addVote = async (value: CombinedVote, teamId: number) => {
    this.setState({ isLoading: true })
    try {
      let { votes } = this.state

      let x = 0
      for (var i = 0; i < votes.length; i++) {
        if (votes[i].value === 5) {
          x++
        }
      }

      votes = votes.filter(v => v.teamId !== teamId)
      votes.push({ teamId, value })

      this.setState({ votes })
      if (typeof value === 'number') {
        await vote(this.props.code, teamId, value)
      } else {
        await vote(
          this.props.code,
          teamId,
          value.business + value.pitch + value.product,
        )
      }
      localStorage.setItem('votes', JSON.stringify(votes))
    } catch (e) {
      alert('Error voting: ' + e.message + '. Try again a bit later.')
    } finally {
      this.setState({ isLoading: false })
    }
  }

  syncVotes = async () => {
    this.setState({ isLoading: true })
    try {
      await Promise.all(
        this.state.votes.map(async voteEntry => {
          if (typeof voteEntry.value === 'number') {
            await vote(this.props.code, voteEntry.teamId, voteEntry.value)
          } else {
            await vote(
              this.props.code,
              voteEntry.teamId,
              voteEntry.value.business +
                voteEntry.value.product +
                voteEntry.value.pitch,
            )
          }
        }),
      )
    } catch (e) {
      alert('Error voting: ' + e.message + '. Try again a bit later.')
    } finally {
      this.setState({ isLoading: false })
    }
    alert('✅ Votes synced with the server')
  }

  get event(): APIEvent {
    const { events } = this.props.data
    const { selectedEvent } = this.state
    if (selectedEvent === 0) {
      return events[0]
    } else {
      return events.find(e => e.id === selectedEvent) as APIEvent
    }
  }

  get teams(): APITeam[] {
    const { event } = this
    return event.teams.sort((a, b) =>
      a.number > b.number ? 1 : a.number < b.number ? -1 : 0,
    )
  }

  get detailPageData(): APITeam {
    const { detailPageId } = this.state
    const { teams } = this
    return teams.find(t => t.id === detailPageId) as APITeam
  }

  get content() {
    const { isLoading } = this.state
    if (this.state.isDetailPage) {
      return (
        <TeamDetails
          onGoBack={() => this.setState({ isDetailPage: false })}
          {...this.detailPageData}
        />
      )
    } else {
      return (
        <>
          <div className={`list ${isLoading && 'loading'}`}>
            {this.teams.map((t, i) => {
              const existingVote = this.state.votes.find(v => v.teamId === t.id)
              let selectedValue
              if (existingVote) {
                selectedValue = existingVote.value
              } else {
                if (this.event.votingType === 'star') {
                  selectedValue = {
                    business: 0,
                    product: 0,
                    pitch: 0,
                  }
                } else {
                  selectedValue = 0
                }
              }
              return (
                <VoteEntry
                  key={t.id}
                  voteType={this.event.votingType}
                  number={t.number}
                  title={t.name}
                  subtitle={`Category: ${t.category} | Track: ${t.track}`}
                  onVotesChange={(value: CombinedVote) =>
                    this.addVote(value, t.id)
                  }
                  voteData={{ selectedValue }}
                  onShowDetails={() =>
                    this.setState({
                      isDetailPage: true,
                      detailPageId: t.id,
                    })
                  }
                />
              )
            })}
          </div>
          <Button
            className='syncButton'
            buttonType='secondary'
            onClick={this.syncVotes}
          >
            Manually sync votes
          </Button>
        </>
      )
    }
  }

  public render() {
    return (
      <>
        <Header
          isLoggedIn={true}
          data={{
            votesCount: this.state.votes.filter(v => v.value > 0).length,
            name: this.props.data.name,
            canCrowdvote: this.props.data.canCrowdvote,
            events: this.props.data.events,
            onEventChange: selectedEvent => this.setState({ selectedEvent }),
            onLogout: this.props.onLogout,
            selectedEventId: this.state.selectedEvent,
          }}
        />
        {this.content}
      </>
    )
  }
}
