/* -------------------------------------------------------------------------- */
/*                               📃 Team details                              */
/* -------------------------------------------------------------------------- */

import './index.scss'
import React from 'react'
import { APITeam } from '../../../services/apiTypes'
import { VoteEntry } from '../VoteEntry'
import { Button } from '../../common/Button'

export interface ITeamDetailsProps extends APITeam {
  onGoBack: () => void
}

export class TeamDetails extends React.Component<ITeamDetailsProps> {
  public render() {
    const { props } = this

    return (
      <div className='TeamDetails'>
        <VoteEntry
          voteType='none'
          title={props.name}
          number={props.number}
          subtitle={`Category: ${props.category} | Track: ${props.track}`}
        />
        <span>Description</span>
        <p>{props.description}</p>
        <Button onClick={this.props.onGoBack}>Go back</Button>
      </div>
    )
  }
}
